<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="700px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title style="background-color: #7253cf; color: white">
          <div class="d-flex">
            <img src="@/assets/basketBall.svg" height="80" />
          </div>
          <div class="ml-7 mb-3 dialogTitle-text text-center">
            Update Ticket Info
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="32"
            color="white"
            @click="toggleTicketInfoModal({ show: false })"
            style="cursor: pointer; margin-top: -60px; margin-right: -12px"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-form lazy-validation ref="ticketInfoForm" class="pt-10">
            <v-row justify="center" no-gutters>
              <v-col cols="4" class="text-center"></v-col>
              <v-col cols="4" class="text-center">
                <div class="ticketMode">Online</div>
              </v-col>
              <v-col cols="4" class="text-center">
                <div class="ticketMode">Paper</div>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              no-gutters
              v-for="(item, i) in items"
              :key="i"
            >
              <div class="d-flex justify-center" style="width: 100%">
                <v-col cols="4" class="text-center">
                  <div class="ticketTitles" readonly>
                    {{ item.ticket_type_name }}
                  </div>
                </v-col>
                <v-col cols="4" class="pr-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="item.total_online_ticket_sold"
                    color="#7253CF"
                    class="formFields"
                    type="number"
                    :disabled="formLoading"
                    readonly
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="item.total_offline_ticket_sold"
                    color="#7253CF"
                    class="formFields"
                    type="number"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            class="text-capitalize px-7"
            height="45"
            dark
            color="#38227A"
            style="border-radius: 10px"
            @click="submitForm"
          >
            <span>Update ticket info</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Axios from "@/api/BaseAxios";
import {
  ADMIN_TICKET_TYPE_LIST,
  GAME_TICKET_INFO_POST_API,
} from "@/constants/APIUrls";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddTicketInfoModal",
  data() {
    return {
      loading: false,
      formLoading: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      getShow: "gameManagement/getTicketShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.getShow;
      },
      set(value) {
        return this.toggleTicketInfoModal({ show: value });
      },
    },
    ticketInfo() {
      return this.selectGameData.ticket_type;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleTicketInfoModal: "gameManagement/toggleTicketInfo",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getTicketList();
    },
    closeModal() {
      this.formLoading = false;
      this.loading = false;
    },
    getTicketList() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.ticket_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        ADMIN_TICKET_TYPE_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      const successHandler = (res) => {
        console.log(res.data);
        this.$emit("reload");
        this.showToast({
          message:"Ticket information updated.",
          color:"s"
        })
        this.toggleTicketInfoModal({show:false})
      }
      const failureHandler =(res)=>{
        console.log(res)
      }
      let formData={}
      formData["game"] = this.$route.query.game_id
      formData["ticket_detail"] = this.items
      Axios.request_POST(
        GAME_TICKET_INFO_POST_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getFilteredTicketItems(item) {
      console.log(item);
      let filteredArray = item.filter((i) => this.ticketInfo.includes(i.id));
      return filteredArray;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.ticketTitles {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 115%;
  /* or 22px */

  text-align: center;

  color: #2d2d2d;
}
.ticketMode {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
</style>
